import {
  ADMIN_DETAIL,
  SUB_ADMIN_DETAIL,
  HIRE_EXPERT_FAILED,
  HIRE_EXPERT_LOADING,
  HIRE_EXPERT_SUCCESSFULL,
  UPDATE_HIRE_EXPERT_FAILED,
  UPDATE_HIRE_EXPERT_LOADING,
  UPDATE_HIRE_EXPERT_SUCCESSFULL,
} from "../constant";

const initalState = {
  adminDetail: null,
  hireExpert: {
    loading: false,
    payload: null,
    error: null,
  },
  updateHireExpert: {
    loading: false,
    payload: null,
    error: null,
  },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case ADMIN_DETAIL: {
      return {
        ...state,
        adminDetail: action.payload,
      };
    }
    case SUB_ADMIN_DETAIL: {
      return {
        ...state,
        subAdminAccess: action.payload.data,
      };
    }
    case HIRE_EXPERT_LOADING: {
      return {
        ...state,
        hireExpert: {
          loading: true,
        },
      };
    }
    case HIRE_EXPERT_FAILED: {
      return {
        ...state,
        hireExpert: {
          loading: false,
          payload: action?.payload?.error,
        },
      };
    }
    case HIRE_EXPERT_SUCCESSFULL: {
      return {
        ...state,
        hireExpert: {
          loading: false,
          payload: action?.payload?.data,
        },
      };
    }
    case UPDATE_HIRE_EXPERT_LOADING: {
      return {
        ...state,
        updateHireExpert: {
          loading: true,
        },
      };
    }
    case UPDATE_HIRE_EXPERT_FAILED: {
      return {
        ...state,
        updateHireExpert: {
          loading: false,
          payload: action?.payload?.error,
        },
      };
    }
    case UPDATE_HIRE_EXPERT_SUCCESSFULL: {
      return {
        ...state,
        updateHireExpert: {
          loading: false,
          payload: action?.payload?.data,
        },
      };
    }
    default:
      return state;
  }
};

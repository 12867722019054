import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import userAvatar from "../assets/Images/profileAvatar.png";
import { classNames } from "../utils/classNames.js";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
export default function NavbarMenu() {
  const navigate = useNavigate();
  const subAdmin = JSON.parse(localStorage.getItem("adminInfo"))?.accountTypes?.[0]?.type;
  // console.log(subAdmin,"subAdmin")
  const options = [
    {
      id: 1,
      title: "Logout",
    },
  ];
const handleLogout = () => {
  localStorage.clear();
  navigate("/login")
}
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center gap-x-2">
        <Menu.Button className="flex items-center rounded-full p-0.5 focus:outline-none">
          <span className="sr-only">Open options</span>
          <div className="flex items-center px-2 gap-x-3 bg-c_EAEAEA max-w-[200px] h-[54px] border border-c_BF9056 rounded-[27px]">
            <img
              src={userAvatar}
              alt="profileImage"
              className="w-[40px] h-[40px] rounded-full object-cover"
            
            />
            <div className="flex gap-1">
            <span className="text-c_#3C3C43/85 font-general_semiBold font-semibold capitalize text-f_14 ">
              {subAdmin === "subadmin" ? "Sub Admin" : subAdmin}

            </span>
            {/* {subAdminAccessData ? 
            <span className="text-c_#3C3C43/85 font-proxima_semiBold font-semibold text-f_14 ">
              {subAdminAccessData?.lastName?.length > 5 ? subAdminAccessData?.lastName?.substring(0,5)+"..." : subAdminAccessData?.lastName || ''}

            </span> :
             <span className="text-c_#3C3C43/85 font-proxima_semiBold font-semibold text-f_14 ">
             {admin?.lastName?.length > 5 ? admin?.lastName?.substring(0,5)+"..." : admin?.lastName || ''}

           </span>
            } */}
            </div>
           
            <ChevronDownIcon
              className="h-4 w-4 text-c_000"
              aria-hidden="true"
            />
          </div>
          {/* {
            <img
              src={userAvatar}
              alt="profileImage"
              className="w-10 h-10 rounded-full object-cover"
            />
          } */}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-xl bg-c_F9F9F9 text-c_000 shadow-md focus:outline-none">
          <div className="">
            {options.map((option, index) => (
              <Menu.Item key={`option-${index}`}>
                {({ active }) => (
                  <React.Fragment>
                    <a
                      onClick={handleLogout}
                      className={classNames(
                        active
                          ? "text-c_000"
                          : "text-c_C9C9C9 hover:text-c_BB9847",
                        "block px-4 py-3.5 text-sm font-general_light cursor-pointer"
                      )}
                    >
                      {option.title}
                    </a>
                    {options.length - 1 === index ? null : (
                      <div className="border-b border-c_ffffff17 mx-2" />
                    )}
                  </React.Fragment>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

/** @format */

import toast from "react-hot-toast";
import { ADMIN_DETAIL } from "../constant";
import { endPoint } from "../../api/EndPoint";
import _Api from "../../api/apiConfig";

const adminLoginAction = (obj, callBack, loading) => {
  loading(true);
  return async (dispatch) => {
    await _Api._post(
      endPoint.adminLogin,
      obj,
      (success) => {
        loading(false);
        callBack(success);
        toast.success(success?.data?.message);
        dispatch({
          type: ADMIN_DETAIL,
          payload: { data: success?.data?.data },
        });
        localStorage.setItem("email", success?.data?.data?.email);
        localStorage.setItem("adminInfo", JSON.stringify(success?.data?.data));
      },
      (error) => {
        loading(false);
        toast.error(error?.message);
      },
    );
  };
};

const adminVerifyOtpAction = (obj, callBack, loading) => {
  loading(true);
  return async (dispatch) => {
    await _Api._post(
      endPoint.adminVerifyOtp,
      obj,
      (success) => {
        loading(false);
        callBack(success);
        toast.success(success?.data?.message);
        localStorage.setItem("token", success?.data.data);
      },
      (error) => {
        loading(false);
        toast.error(error?.message);
      },
    );
  };
};

const verifyBusinessAction = (id, callBack) => {
  return async (dispatch) => {
    await _Api._patch(
      `${endPoint.updateVerifyBusiness}/${id}`,
      {},
      (success) => {
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        toast.error(error);
      },
    );
  };
};

const startupSoldAction = (id, callBack) => {
  return async (dispatch) => {
    await _Api._patch(
      `${endPoint.soldUnSold}/${id}`,
      {},
      (success) => {
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        toast.error(error);
      },
    );
  };
};

const forgotPasswordAction = (obj, callBack) => {
  return async (dispatch) => {
    await _Api._post(
      endPoint.forgotPassword,
      obj,
      (success) => {
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        toast.error(error?.message);
      },
    );
  };
};

const resendVerificationPasswordAction = (obj, callBack) => {
  return async (dispatch) => {
    await _Api._post(
      endPoint.resendVerificationPassword,
      obj,
      (success) => {
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        toast.error(error?.message);
      },
    );
  };
};

const getSingleUser = async (id, user, setLoading) => {
  setLoading(true);
  await _Api._get(
    `${endPoint.usersGetSingleUser}/${id}`,
    (res) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      user(res?.data);
    },
    (error) => {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      toast.error(error);
    },
  );
};

const fileUploadAction = (obj, callBack, loader) => {
  loader(true);
  return async (dispatch) => {
    await _Api._postImage(
      endPoint.fileUpload,
      obj,
      (success) => {
        loader(false);
        console.log(success, "success");
        callBack(success);
        // toast.success(success?.data?.message);
      },
      (error) => {
        loader(false);
        // toast.error(error);
      },
    );
  };
};

const userVerifyOtpAction = (obj, callBack, loading) => {
  loading(true);
  return async (dispatch) => {
    await _Api._post(
      endPoint.subAdminVerifyOtp,
      obj,
      (success) => {
        loading(false);
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        loading(false);
        toast.error(error?.message);
      },
    );
  };
};
const resetPasswordAction = (obj, callBack) => {
  return async (dispatch) => {
    await _Api._post(
      endPoint.resetPassword,
      obj,
      (success) => {
        callBack(success);
        toast.success(success?.data?.message);
      },
      (error) => {
        toast.error(error?.message);
      },
    );
  };
};

export {
  fileUploadAction,
  getSingleUser,
  startupSoldAction,
  verifyBusinessAction,
  resendVerificationPasswordAction,
  adminLoginAction,
  adminVerifyOtpAction,
  resetPasswordAction,
  forgotPasswordAction,
  userVerifyOtpAction,
};

/** @format */

// import { useState } from 'react'
// import reactLogo from './assets/react.svg'
// import viteLogo from '/vite.svg'
import { Fragment, useEffect } from "react";
import "./App.css";
import { setKey, setLanguage } from "react-geocode";
import AppRoutes from "./routers";
import { Toaster } from "react-hot-toast";
import "flatpickr/dist/flatpickr.min.css";
import { getEnvVariable } from "./utils/validate";

function App() {
  useEffect(() => {
    setKey(getEnvVariable('VITE_APP_GOOGLE_MAP_API_KEY'));
    setLanguage("en");
  }, []);

  return (
    <Fragment>
      <div className='App'>
        <div className='top-logo'></div>
        <div className='bottom-logo'></div>
        <AppRoutes />
        <Toaster position='top-center' />
      </div>
    </Fragment>
  );
}

export default App;

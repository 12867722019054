/** @format */

export const endPoint = {
  adminLogin: "users/admin-login",
  resetPassword: "users/reset-password",
  adminVerifyOtp: "users/admin-verification",
  subAdminVerifyOtp: "users/verification",
  forgotPassword: "users/forget-password",
  resendVerificationPassword: "users/resend-verification-code",
  addMarketingCode: "marketing-code/add-marketing-Code",
  getMarketingCode: "marketing-code/all-marketing-Code",
  marketingStatusChange: "marketing-code/change-status",
  addUser: "users/add-User",
  getUser: "users/all-user",
  locationName: "users/location-name",
  userStatusChange: "users/change-status",
  helpDesk: "hire-expert/all-help-desks",
  soldUnSold: "form/sold-unsold-admin",
  developerStatusChange: "users/change-developer-status",
  exportExcelUser: "file-exports/export-users",
  exportSubAdmin: "file-exports/export-sub-admins",
  exportUserPromoCode: "file-exports/export-promo-code-detail",
  exportContactRequest: "file-exports/export-contact-requests",
  exportStartupsFile: "file-exports/export-start-up",
  exportPayments: "file-exports/export-promotion-transactions",
  exportMarketingCode: "file-exports/export-marketing-codes",
  exportUserMarketingCode: "file-exports/export-users-marketing-code",
  exportPromoCode: "file-exports/export-promo-codes",
  exportSubscriptionPayments: "file-exports/export-subscription-transactions",
  addStartupForUser: "form/admin-add-start-up",
  addUserDetails: "users/update-details",
  promotionPayment: "promote/transaction-detais",
  editBusinessSetting: "subscription/edit-business-setting",
  userStatusEnableDisable: "file-exports/enable-disable",
  getFormDetails: "form/get-steps-detail",
  fileUpload: "file-upload",
  editDynamicForm: "form/edit-dynamic-form",
  getEditStartupForm: "form/edit-startup-form",
  dashboard: "dashboard",
  startupDetails: "form/startup-detail",
  allPromoCode: "promo-code/all-promo-codes",
  promoteStatusChange: "promote/chnage-status",
  updatePromotePackage: "promote/edit",
  reportedStartups: "reported-start-ups",
  updateBusinessSubscription: "subscription/add-business-setting",
  users: "users",
  businessSubscription: "subscription/all-business-setting",
  addFormStep: "form/add-steps",
  deleteStartup: "form/delete-single",
  updateVerifyBusiness: "form/update-activation-business-verification",
  promoCodeDetails: "promo-code/promo-code-detail",
  addSetting: "setting/add",
  businessSetting: "setting",
  stepVisibility: "form/step-visibility",
  marketingCodeDetails: "marketing-code/users-marketing-code",
  allSubscription: "subscription/all-subscription",
  getStartups: "form/start-up-list",
  verifyBusinessStartup: "form/business-verification",
  getFormStep: "form/all-steps",
  getFormFields: "form/form-fields",
  addHireExpert: "hire-expert/hire-expert",
  getHireExpert: "hire-expert/hire-experts",
  changeStatusHireExpert: "hire-expert/change-status",
  getStartupSellerForm: "form/seller-form",
  getSingleHireExpert: "hire-expert/hire-expert",
  updateHireExpert: "hire-expert/hire-expert",
  addDynamicForm: "form/add-dynamic-form",
  addPromoCode: "promo-code",
  promoStatusChange: "promo-code/change-status",
  deleteStepForm: "form/delete-form-step",
  updateSubAdmin: "users/edit-sub-admin",
  editFormStep: "form/edit-step",
  stepFormStatusChange: "form/status-update",
  addSubAdmin: "users/add-subadmin",
  getSubAmin: "users/all-sub-admin",
  subAdminAccessController: "access-controller/add",
  getSubAdminAccessController: "access-controller",
  getSubscription: "subscription/all-subscription",
  subscriptionPayment: "subscription/transaction-details",
  changeStartupStatus: "form/startup-status",
  getStartupListUser: "form/user-start-up-listings",
  startupVisibilityStatus: "form/start-up-visibility",
  getSingleStartup: "form/single-listing",
  updateUserStartup: "form/update-Form-details",
  requestListing: "listing-request",
  promote: "promote/plans",
  requestStatusChange: "listing-request",
  subscriptionChangeStatus: "subscription/subscription-status",
  editSubscription: "subscription/edit-subscription",
  promoteBusiness: "promote/promote-business",
  dynamicLinkUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
  getAllCountries: "form/countries",
  getAllCities: "form/cities",
  addNewCity: "form/add-city",
  patchEditCities: "form/edit-city",
  formGetCities: "form/cities",
  usersGetSingleUser: "users",
  formPostStartupDetails: "form/start-up-details",
  userVerifyPhoneNumber: "users/verify-phone-code",
  marketingCodeWithoutId: "marketing-code/check-marketing-code",
  usersResendPhoneCode: "users/resend-phone-code",
  marketingCodeAdd: "marketing-code/avail-marketing-code",
  businessVerification: "form/business-verification",
  uploadFile: "/file-upload",
};

import toast from "react-hot-toast";
import { endPoint } from "../../api/EndPoint";
import _Api from "../../api/apiConfig";
import { labels } from "../../assets/labels";
import { SUB_ADMIN_DETAIL } from "../constant";


const addSubAdminAction = (obj, callBack,loader) => {
  loader(true)
    return async (dispatch) => {
      await _Api._post(
        endPoint.addSubAdmin,
        obj,
        (success) => {
          loader(false)
          callBack(success);
          toast.success(success.data?.message);
          // toast.success(labels.subAdminCreatedSuccessfully);
        },
        (error) => {
          loader(false)
          toast.error(error);
        }
      );
    };
  };

  const exportSubAdminAction = (obj, callBack,loader) => {
    loader(true)
    return async (dispatch) => {
      await _Api._postBuffer(
        `${endPoint.exportSubAdmin}`,
        obj,
        (success) => {
          loader(false)
          callBack(success);
        },
        (error) => {
          loader(false)
          toast.error(error);
        }
      );
    };
  };
  
  // sub Admin Access Controller Action Api Method
  const subAdminAccessControllerAction = (obj, callBack) => {
    return async (dispatch) => {
      await _Api._post(
        `${endPoint.subAdminAccessController}`,
        obj,
        (success) => {
          callBack(success);
          toast.success(labels.accessGranted);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };

  

  // update Sub Admin Api Method
const updateSubAdminAction = (id,obj, callBack) => {
  return (dispatch) => {
    return _Api._patch(
      `${endPoint.updateSubAdmin}/${id}`,
      obj,
      (res) => {
        callBack(res);
        toast.success(res?.data?.message);
      },
      (error) => {
        toast.error(error);
      }
    );
  };
};

    // Get User  Api Method
 const getSubAdminAction = async (subAdmin,params) => {
      const query = new URLSearchParams(params).toString();
      await _Api._get(
        `${endPoint.getSubAmin}?${query}`,
        (success) => {
          subAdmin(success?.data);
        },
        (err) => {
          // toast.error(err);
        }
      );
  };

     // Get Access Control  Api Method
 const getAccessControlAction = (subAdmin) => {
  return async (dispatch) => {
  await _Api._getAccessController(
    `${endPoint.getSubAdminAccessController}`,
    (success) => {
      dispatch({ type: SUB_ADMIN_DETAIL, payload: { data: success?.data?.data } });
      subAdmin(success?.data?.data);
    },
    (err) => {
      toast.error(err);
    }
  
  );
  }
};


  // Toggle Status change   Api Method
 const toggleStatusApiMethod = async (id) => {
  await _Api._patch(
    `${endPoint.userStatusChange}/${id}`,{},
    (success) => {
      toast.success("Status updated");
    },
    (err) => {
      toast.error(err);
    }
  );
};
  
  export {getSubAdminAction,exportSubAdminAction,getAccessControlAction,addSubAdminAction,toggleStatusApiMethod,subAdminAccessControllerAction,updateSubAdminAction};
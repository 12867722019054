import React, { Fragment, useState } from "react";
import { labels } from "../../assets/labels";
import Input from "../../components/Input";
import Button from "../../components/Button";
import {
  checkInternetConnection,
  validateEmailAddress,
  validateText,
} from "../../utils/validate";
import { useNavigate } from "react-router";
import { Images } from "../../utils/images";
import { forgotPasswordAction } from "../../store/actions";
import { useDispatch } from "react-redux";
import Spinner from "../../components/spinner/authLoader";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { ForgotKey, IstehwathLogo } = Images;
  const [values, setValues] = useState({
    email: "",
  });
  const [errors, setErrors] = useState({
    email: null,
  });

  const handleForgotPassword = (e) => {
    e.preventDefault();
    if (!values?.email) {
      const textError = validateText(values?.email);
      setErrors((prevState) => ({
        ...prevState,
        email: textError,
      }));
    }
    if (values?.email) {
      const emailError = validateEmailAddress(values?.email);
      setErrors((prevState) => ({
        ...prevState,
        email: emailError,
      }));
    }
    if (values?.email && validateEmailAddress(values?.email) === null){
      if (Boolean(checkInternetConnection())) {
        setIsLoading(true);
        dispatch(forgotPasswordAction({email: values?.email},(res) => {
          setIsLoading(false);
          navigate("/forgot-password-otp",{state: values?.email});
        }));
      }
    }
  };
  return (
    <Fragment>
      <div className="w-full min-h-screen grid place-content-center">
        <div className="flex flex-col gap-y-16 items-center justify-between">
          <img src={IstehwathLogo} alt="Otp Logo" className="object-cover" />

          <div className="flex justify-center items-center flex-col md:px-0 px-4">
            <div className="mb-8">
              <img
                src={ForgotKey}
                alt="Istehwath Logo"
                className="object-cover w-[141.99px] h-[117px]"
              />
            </div>
            <p className="text-c_000000 font-general_semiBold font-semibold text-f_32 md:my-0 my-4">
              {labels.forgotPassword}
            </p>
            <p className="text-f_13 font-general_regular text-c_0E1014 font-normal">
              {labels.enterYourEmailWeWillSendOtp}
            </p>
          </div>
        </div>
        <form>
          <div className="flex flex-col gap-3 mt-8 mb-8 md:px-0 px-4">
            <Input
              id="email"
              name="email"
              type="email"
              value={values?.email}
              onChange={(e) => {
                setErrors((prevState) => ({
                  ...prevState,
                  email: null,
                }));
                setValues((prevState) => ({
                  ...prevState,
                  email: e.target.value?.replace(/\s/g, ""),
                }));
              }}
              error={Boolean(errors.email)}
              errorText={errors.email}
              className={`w-full border border-c_D9D9D9 rounded-[12px] py-2.5 placeholder:capitalize lowercase px-4 text-[16px] font-general_regular text-c_0E1014 outline-none placeholder:!text-c_D9D9D9`}
              placeholder={labels.emailAddress}
            />
            <Button
              label={isLoading ? <Spinner /> : labels.send}
              type="submit"
              disabled={isLoading && errors?.email}
              onClick={handleForgotPassword}
              className="bg-gradient-to-r from-[#1C2F40]  to-[#20415E] h-[49px] font-general_medium font-medium  text-[16px] text-c_FFFFFF rounded-[8px] mt-3"
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ForgotPassword;

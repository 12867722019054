/** @format */

import React, { Fragment, useEffect, useState } from "react";
import Button from "../../components/Button";
import { labels } from "../../assets/labels";
import "./otpInput.style.css";
import OTPInput from "otp-input-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../utils/images";
import Spinner from "../../components/spinner/authLoader";
import toast from "react-hot-toast";
import {
  adminVerifyOtpAction,
  resendVerificationPasswordAction,
} from "../../store/actions";
import { useDispatch } from "react-redux";
import { getAccessControlAction } from "../../store/actions/SubAdminActions";

const { VerifyOtpLogo, IstehwathLogo } = Images;

const subAdminRoute = {
  promote: "/promote",
  payment: "/payments",
  dashboard: "/dashboard",
  users: "/users",
  startup: "/startup",
  request: "/contact-request",
  marketingCode: "/marketing-code",
  promoCode: "/discount-code",
  subscription: "/subscription",
};

const VerifyOtp = () => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accessController, setAccessController] = useState([]);
  const [time, setTime] = useState("");
  const subAdmin = JSON.parse(localStorage.getItem("adminInfo"))
    ?.accountTypes?.[0]?.type;
  const location = useLocation();
  const dispatch = useDispatch();

  const subAdminAccessFunc = () => {
    setTimeout(() => {
      dispatch(
        getAccessControlAction((res) => {
          if (!res?.length) {
            toast.error(
              "You don't have access to any features of the admin portal",
            );
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          } else {
            navigate("/dashboard");
          }
          // if (res?.some((item) => item.features === "dashboard" && item.read)) {

          // }
          // navigate("/users");
        }),
      );
    }, 1000);
  };
  useEffect(() => {
    timeFunc();
  }, []);
  const timeFunc = () => {
    let secs = 29,
      min = 0;
    let interval = setInterval(() => {
      if (min === 0 && secs === 0) {
        setShow(true);
        clearInterval(interval);
      }
      if (secs === 0) {
        secs = 59;
        min -= 1;
      }
      if (secs < 10) {
        setTime(`0${min} : 0${secs}`);
      } else {
        setTime(`0${min} : ${secs}`);
      }
      secs--;
    }, 1000);
  };
  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (!OTP) {
      return toast.error(labels.pleaseFillTheField);
    }
    setIsLoading(true);
    dispatch(
      adminVerifyOtpAction(
        { code: OTP, email: location?.state },
        (res) => {
          setIsLoading(false);
          if (res?.data?.data && subAdmin === "subadmin") {
            subAdminAccessFunc();
          } else {
            navigate("/dashboard");
          }
        },
        setIsLoading,
      ),
    );
  };
  const resendCodeFunc = () => {
    const payload = { email: location?.state };
    dispatch(
      resendVerificationPasswordAction(payload, () => {
        setShow(false);
        setOTP("");
        timeFunc();
      }),
    );
  };
  return (
    <Fragment>
      <div className='w-full min-h-screen grid place-content-center'>
        <div className='flex flex-col gap-y-16 items-center justify-center'>
          <div className=''>
            <img src={IstehwathLogo} alt='Otp Logo' className='object-cover' />
          </div>
          <div className='items-center justify-center flex flex-col gap-y-1'>
            <img
              src={VerifyOtpLogo}
              alt='Otp Logo'
              className='object-cover w-[177.23px] h-[117.48px]'
            />
            <p className='text-c_181818 font-general_semiBold font-semibold text-f_32'>
              {labels.verify}
            </p>
            <div className='flex gap-1'>
              <span className='font-general_light font-normal text-c_181818 text-[16px]'>
                {labels.codeWasSentTo}
              </span>
              <span className='font-general_medium font-medium text-c_0E1014 text-[16px]'>
                {location?.state}
              </span>
            </div>
          </div>
        </div>
        <form>
          <div className='flex flex-col justify-center items-center gap-y-3 mt-8 mb-8'>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus
              OTPLength={6}
              otpType='number'
              className='gap-2 otp-input'
            />
            <Button
              label={isLoading ? <Spinner /> : labels.verifyBtn}
              type='submit'
              disabled={isLoading}
              onClick={handleVerifyOtp}
              className='w-full bg-gradient-to-r from-[#1C2F40]  to-[#20415E] h-[49px] font-general_medium font-medium  text-[16px] text-c_FFFFFF rounded-[8px] py-2'
            />
            <div className='flex justify-center items-center'>
              <div className='flex gap-x-1'>
                <span className='text-[16px] text-c_181818  font-general_medium font-medium'>
                  {labels.didNotReceiveEmail}
                </span>
                <span
                  onClick={resendCodeFunc}
                  className={
                    show
                      ? "cursor-pointer text-[16px] text-c_1C2F3E  font-general_medium font-medium"
                      : "text-[16px] text-c_1C2F3E  font-general_medium font-medium opacity-40 pointer-events-none"
                  }
                >
                  {labels.resend}
                </span>
              </div>
            </div>
            {!show ? (
              <span className='text-c_181818 text-[16px]'>{time}</span>
            ) : null}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default VerifyOtp;

/** @format */

export const labels = {
  welcomeBackAdmin: "Welcome back to admin!",
  enterTheEmail: "Enter the email address and password to login",
  emailAddress: "Email Address",
  newPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  password: "Password",
  rememberMe: "Remember me",
  login: "Login",
  verify: "Verify OTP",
  codeWasSentTo: "The code was sent to",
  addFileUploader: "Add File Uploader",
  yearlyDiscountWebPrice: "Yearly Discount Web Price",
  yearlyDiscountAppPrice: "Yearly Discount App Price",
  monthlyDiscountWebPrice: "Monthly Discount Web Price",
  monthlyDiscountAppPrice: "Monthly Discount App Price",
  reportedStartups: "Reported Businesses",
  areYouSureYouWantToSoldStartup:
    "Are you sure you want to sold this business?",
  promotion: "Promotions",
  isDeveloper: "isDeveloper",
  markAsUnSold: "Mark as Unsold",
  other: "Other",
  addTextarea: "Add Textarea",
  buyerEmail: "Buyer Email",
  markAsSold: "Mark as sold",
  contactRequest: "Contact Request",
  promoteBusiness: "Promote Business",
  startupTitle: "Business Title",
  explanation: "Explanation",
  buyerPhone: "Buyer Phone",
  helpDesk: "Help Desk",
  SellerstartupsDetail: "Seller Business Detail",
  directRequest: "Direct Request",
  InDirectRequest: "Indirect Request",
  sent: "Sent",
  discountFor: "Discount For",
  businessArea: "Business Area",
  platformType: "Platform Type",
  web: "Web",
  mobile: "Mobile",
  contact: "Contact",
  payment: "Payments",
  createdAt: "Created At",
  excel: "Excel",
  sold: "Sold",
  platform: "Platform",
  isInputRequired: "Is input required",
  draftStartup: "Draft Business",
  discountPrice: "Discount Price",
  transactionId: "Transaction ID",
  pleaseSelectPromotePlan: "Please select promote plan",
  unlimited: "Unlimited",
  enable: "Enable",
  webPrice: "Web price",
  disable: "Disabled",
  buyerName: "Buyer Name",
  daysRemaining: "days remaining",
  descriptionInArabic: "Description In Arabic",
  verifiedBusinessSuccessfully: "Verified Business Successfully",
  addUserStartupSuccessfully: "Add User Business Successfully",
  editUserStartupSuccessfully: "Edit User Business Successfully",
  businessPromoted: "Business Promoted",
  reportedstartupsDetail: "Reported Business Detail",
  editPromotePackage: "Edit Promote Package",
  statusUpdated: "Status Updated",
  userstartupsDetail: "User Business Detail",
  subscriptionPlan: "Subscription Plan",
  businessSettingUpdated: "Business Setting Updated",
  promotePackageUpdated: "Promote Package Updated",
  businessCategoryUpdated: "Business Category Updated",
  iosIdentifier: "Yearly iOS Identifier",
  iosIdentifierDiscount: "iOS Identifier",
  iosIdentifiers: "iOS Identifier",
  androidIdentifier: "Yearly Android Identifier",
  androidIdentifierDiscount: "Android Identifier",
  discountDuration: "Discount Duration",
  androidIdentifiers: "Android Identifier",
  monthlyIosIdentifier: "Monthly IOS Identifier",
  monthlyAndroidIdentifier: "Monthly Android Identifier",
  yearlyAndroidIdentifier: "Yearly Android Identifier",
  yearly: "Yearly",
  monthly: "Monthly",
  descriptionInEnglish: "Description In English",
  userStartupAddedSuccessfully: "User Business Added Successfully",
  addressOne: "Address 1",
  settingUpdated: "Setting Updated",
  subscriptionUpdated: "Subscription Updated",
  uploading: "Uploading...",
  startupDeleted: "Business Deleted",
  keepIsHide: "Keep this hide",
  selectCountry: "Select Country",
  buyerCannotSeeBusinessName: "Buyer cannot see business name",
  financials: "Financial",
  uploadLetterWithCompanyHeader: "Upload letter with company header",
  addBusinessFinanceDetails: "Add business finance details",
  verifyBusiness: "Verify Business",
  addBusinessDetails: "Add business details",
  ttmGrossRevenus: "TTM Gross Revenue",
  ttmGrossRevenusStartup: "TTM Gross Revenue for the last 12 months",
  ttmNetProfit: "TTM Net Profit",
  ttmNetProfitStartup: "TTM Net Revenue for the last 12 months",
  accepted: "Accepted",
  yourRoleInBusiness: "Your role in business",
  authorizationLetter: "Authorization letter",
  authenticatingYourBusinessWillIncreaseTheChance:
    "Authenticating your business will increase the chance",
  iDeclareThatIHaveFullAuthorityToList:
    "I declare that I have full authority to list this business and that I want to list it on an Istehwath platform.",
  toGiveYourBusinessAMarkOfAuthentication:
    "To give your business a mark of authentication, you must attach a letter of authorization from the Chamber of Commerce with a letterhead containing the company logo.",
  saveDraft: "Save Draft",
  dragAndDropOr: "Drag & drop or",
  wrongFileType: "Wrong File Type Format",
  chooseFile: "choose file",
  accessGranted: "Access Granted",
  underReview: "Under Review",
  toUpload: "to upload",
  dateError: "Start date can't be greater than end date.",
  userCreatedSuccessfully: "User Created Successfully",
  promoCodeCreatedSuccessfully: "PromoCode Created Successfully",
  subAdmin: "sub admin",
  areYouSureYouWantToDelete: "Are you sure you want to delete?",
  subAdminCreatedSuccessfully: "Sub Admin Created Successfully",
  usersDetailsAddedSuccessfully: "Users Detail Updated Successfully",
  review: "Review",
  rejected: "Rejected",
  editInput: "Edit Input",
  resend: "Resend",
  verifyBtn: "Verify",
  editStartup: "Edit Business",
  forgotPassword: "Forgot Password",
  requiredField: "Required Field",
  editMonthYear: "Edit Month Year",
  editRadioButton: "Edit Radio Button",
  editFileUploader: "Edit File Uploader",
  editTextarea: "Edit Textarea",
  editCreateTag: "Edit Create Tag",
  editLocation: "Edit Location",
  editDropdown: "Edit Dropdown",
  save: "save",
  steric: "*",
  addCreateTag: "Add Create Tag",
  didNotReceiveEmail: "Didn't receive email yet?",
  addRadioButton: "Add Radio Button",
  options: "Options",
  uploadProfitLossStatement: "Upload your profit and loss statement",
  addMoreFileUploader: "+ Add More file uploader",
  doYouWantSellBusiness:
    "Do you want to sell whole business or partial business",
  businessLocation: "Business Location",
  pleaseFillTheField: "Please fill the field",
  DateOfBirth: "Date of birth",
  city: "City",
  send: "Send",
  select: "Select",
  addLocation: "Add Location",
  yesReject: "Yes, Reject",
  review: "Review",
  exportPDF: "Export PDF",
  addInput: "Add Input",
  addDropdown: "Add Dropdown",
  businessType: "Business Type",
  addMonthYear: "Add Month Year",
  addFile: "Add File",
  option: "Option",
  dropdownOption: "Dropdown Option",
  labelInEnglish: "Label in English",
  english: "English",
  arabic: "Arabic",
  arabics: "الخيار",
  labelInArabic: "Label in Arabic",
  placeholderInEnglish: "Placeholder in English",
  placeholderInArabic: "Placeholder in Arabic",
  keepThisRequired: "Keep this required",
  file: "File",
  textarea: "Textarea",
  uploadFile: "Upload File",
  type: "Type",
  radioButton: "Radio Button",
  addTags: "Add Tags",
  settings: "Settings",
  requests: "Requests",
  approved: "Approved",
  documents: "Documents",
  congratulations: "Congratulations!",
  monthYear: "Month Year",
  input: "Input",
  dropdown: "Dropdown",
  yesNO: "NO/YES",
  text: "Text",
  addDefaultPhoneNumber: "Add Default Phone Number",
  addDefaultPhoneNumberText: "You can Manage and set Phone Number for Request",
  selectedStartup: "Selected Business",
  selectPromotePlan: "Select Promote Plan",
  businessSetting: "Business Setting",
  businessSettingText: "You can Manage and set Business",
  startupForm: "Business Form",
  startupFormText:
    "You can Manage and set Mandatory/Optional Business Form fields",
  verifiedBusinessAuthentication: "Verified Business Authentication",
  verifiedBusinessAuthenticationText:
    "Before complete registration, Seller has to verify business",
  okay: "okay",
  viewListing: "View Listing",
  reject: "Reject",
  selectReason: "Select Reason",
  rejectStartup: "Reject Business",
  searchLocation: "Search Location",
  promoteReactMorefaster: "Promote & Get Reach More Faster",
  promoteStartupThirtyDays: "Promote This business for 30 days",
  promoteStartupFourteenDays: "Promote This business for 14 days",
  promoteStartupSevenDays: "Promote This business for 7 days",
  promoteReactMorefasterText:
    "Please select the plan that matches your preferences from the three options below.",
  writeReason: "Write Reason",
  document: "Document",
  copyListingUrl: "Copy business URL",
  editListing: "Edit business",
  changeCategory: "Change Category",
  VerifyBusiness: "Verify Business",
  TTMGrossRevenue: " TTM Gross Revenue for the last 12 months",
  plStatement: "P&L statement",
  BusinessCertificate: "Business certificate",
  AnyOtherDocument: "Any other document",
  pitchDeckAndAnyFinancialDocument: "Pitch Deck and any financial document",
  TTMNetRevenue: "TTM Net Revenue for the last 12 months",
  KeyAssets: "Key Assets",
  finance: "Finance",
  companyGrowth: "Company Growth",
  competitors: "Competitors",
  verifiedBusiness: "Verified Business",
  documentHistory: "Document History",
  startupsDetail: "Business Detail",
  FoundedDate: "Founded Date",
  teamSize: "Team Size",
  receiveRequest: "Receive Request",
  receiveContactRequest: "Receive Contact Request",
  askingPriceReasoning: "Asking Price Reasoning",
  askingPriceReasoningText:
    "Based on the current Acquire quarterly multiples report, we are asking for a 5x TTM revenue multiplier",
  askingPrice: "Asking Price",
  startupID: "Business ID",
  approve: "Approve",
  asking: "Asking",
  city: "City",
  sar: "SAR",
  apply: "Apply",
  clear: "Clear",
  revenue: "Revenue",
  all: "All",
  startupDetail: "Business Detail",
  enterYourEmailWeWillSendOtp:
    "Enter your email address We'll send you OTP for new password",
  save: "Save",
  filter: "Filter",
  hide: "Hide",
  allDone: "All Done",
  selectAction: "Select Action",
  applayAction: "Apply Action",
  exportInPDF: "Export in PDF",
  exportInExcel: "Export In Excel",
  selectSectionExportText:
    "Please select the sections that you would like to export.",
  yourPasswordReset: "Your password has been reset.",
  continue: "Continue",
  dashboard: "Dashboard",
  enabled: "Enabled",
  startDate: "Start Date",
  endDate: "End Date",
  usersCount: "7.5k",
  startupCount: "7.5k",
  user: "user",
  startup: "Business Requests",
  firstName: "First Name",
  lastName: "Last Name",
  usersCategory: "Users Category",
  aboutYourself: "About Yourself",
  profilePicture: "Profile Picture",
  search: "Search",
  upload: "Upload",
  businesscategory: "Business Category",
  category: "Category",
  completeProfileText:
    "You need to complete your profile before than you can access the business.",
  basic: "basic",
  premium: "premium",
  platinum: "platinum",
  completeProfile: "Complete Profile",
  skipNow: "Skip Now",
  silver: "silver",
  gold: "gold",
  business: "Business",
  userDetails: "User Details",
  exportPDF: "Export PDF",
  exportExcel: "Export Excel",
  userType: "User Type",
  addNew: "+ Add Now",
  users: "users",
  add: "Add",
  cancel: "Cancel",
  addNewUser: "Add New User",
  userName: "Muhammad Ibrahim",
  userNames: "Username",
  userDesignation: "Founder/Entrepreneur",
  userPlan: "platinum",
  details: "details",
  draftListing: "draft listing",
  information: "information",
  aboutMe: "about me",
  dateOfBirth: "date of birth",
  role: "Role",
  city: "City",
  country: "Country",
  aboutMeDummy:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt.",
  startupListedForSell: "Business Listed for Sell",
  edit: "edit",
  muhammad: "muhammad",
  visibility: "Visibility",
  ibrahim: "ibrahim",
  userEmail: "muhammad@mail.com",
  DateOfBirthUser: "24 Nov 1992",
  founder: "Founder",
  jeddah: "Jeddah",
  saudiarabia: "saudi arabia",
  addStartupForSell: "+ Add Business for sell",
  askingPrice: "Asking price",
  ttmRevenu: "TTM Revenue",
  noOfCustomer: "Number of customer",
  dateFounded: "Date Founded",
  startUpTeam: "Business Team",
  promoted: "promoted",
  Sno: "#",
  title: "title",
  startUpId: "Business ID",
  asking: "Asking",
  postedBy: "Posted By",
  actions: "actions",
  phone: "Phone",
  location: "Location",
  userCategory: "User Category",
  category: "Category",
  delete: "Delete",
  editProfile: "Edit profile",
  subscription: "Subscriptions",
  description: "description",
  typeHere: "Type Here..",
  Addnewstartupforsale: "Add new business for sale",
  back: "back",
  next: "next",
  businessInfo: "Business Information",
  completeBusinessInfo: "Complete Business information",
  subscriptionText: "You can Manage and set buyer subscriptions feature",
  businessName: "Business Name",
  startUpTeamSize: "Start Up Team Size",
  select: "Select",
  businessType: "Business Type",
  startupHeadline: "Business Headline",
  askingPriceReason: "Asking Price Reasoning",
  request: "Request",
  buyer: "Buyer",
  startupCategory: "Business Category",
  seller: "seller",
  received: "received",
  responded: "responded",
  sendDetailstoBuyer: "Send Details To Buyer",
  pleaseEnterYourPhoneNo:
    "Please enter your phone number to send detail to buyer",
  sendDefaultPhoneNo: "Send Default Phone Number",
  sendOtherPhoneNo: "Send Other Phone Number",
  sendOtherPhoneNo: "Send Other Phone Number",
  phoneNumber: "Phone Number",
  saudiaPhoneCode: "+966",
  yourDetailhasbeenSend: "Your Detail has been Sent",
  DetailsSendtoBuyer: "Details Send to Buyer",
  subadmin: "Sub Admin",
  addNewSubAdmin: "Add New Sub Admin",
  name: "Name",
  status: "Status",
  accessController: "Access Controller",
  dummyNumber: "+000 0000 000",
  phoneNo: "Phone No",
  accessControllerRules:
    "Please choose which features you would like to grant access to the sub-administrator.",
  features: "Features",
  read: "Read",
  write: "Write",
  export: "Export",
  marketingCode: "Marketing Code",
  promoCode: "Promo Code",
  discountCode: "Discount Code",
  promote: "promote",
  editSubAdminInfo: "Edit Sub Admin",
  editAdminName: "Edit Admin Name",
  editAdminEmail: "Edit Admin Email",
  editAdminPhone: "Edit Admin Phone",
  code: "Code",
  salesPerson: "Sales Person",
  registeredStartupViaCode: "Registered Business via code",
  startupId: "Business ID",
  sellerName: "Seller Name",
  qatar: "Qatar",
  oman: "Oman",
  bahrain: "Bahrain",
  kuwait: "Kuwait",
  marketingCodeDescrptionDummy:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem totam rem aperiam eaque ipsa quae ab illo explicabo.",
  addMarketingCode: "Add Marketing Code",
  salesPersonName: "Sales Person Name",
  marketingCodeDummyId: "AM1234456",
  johnDoe: "John Doe",
  discount: "Discount",
  duration: "Duration",
  usageType: "Usage Type",
  usageTime: "Usage Time",
  addNewPromoCode: "Add New Promo Code",
  addNewDiscountCode: "Add New Discount Code",
  forBuyerDuringRegistration: "For buyer during registration process",
  discountType: "Discount Type",
  percentage: "Percentage",
  fixedAmount: "Fixed Amount",
  zeroToHundred: "0-100",
  percentageSymbol: "%",
  singleTime: "Single Time",
  multiTime: "Multi Time",
  sar: "SAR",
  unlimitedRequest: "Unlimited Request",
  noOfUsageCount: "No of usage count",
  promoCodeDummyId: "AM1234456",
  usedUsersViaCode: "Used users via code",
  hireExpert: "Hire Expert",
  addHireExpert: "Add Hire Expert",
  editHireExpert: "Edit Hire Expert",
  enterTitle: "Enter Title",
  editTitle: "Edit Title",
  editDescription: "Edit Description",
  reportedStartup: "Reported Business",
  reason: "Reason",
  reportedBy: "Reported By",
  promotePackages: "promote Packages",
  addPromotePackage: "Add Promote Package",
  name: "Name",
  typeTitle: "Type Title",
  price: "Yearly Web Price",
  webPrice: "Web Price",
  appPrice: "App Price",
  monthlyWebPrice: "Monthly Web Price",
  prices: "Price",
  yearlyAppPrice: "Yearly App Price",
  monthlyAppPrice: "Monthly App Price",
  selectDays: "Select Days",
  requestAtaTime: "Request at a time",
  basicBuyerAccesstoBusiness: "Buyer Access to business",
  silverBuyerAccesstoBusiness: "Silver Buyer Access to business",
  goldBuyerAccesstoBusiness: "Gold Buyer Access to business",
  basicBusiness: "Basic Business",
  silverBusiness: "Silver Business",
  goldBusiness: "Gold Business",
  howManyRequestToBasicBusiness: "How many request buyer can send to business?",
  howManyRequestToSilverBusiness:
    "How many request silver buyer can send to basic business?",
  howManyRequestToGoldBusiness:
    "How many request gold buyer can send to basic business?",
  noOfRequest: "No Of Request",
  directRequset: "Direct Requset",
  range: "Range",
  youCanManageAndSetBusiness: "You can Manage and set Business",
  basicBusinessSetting: "Business Setting",
  zeroOne: "01",
  basicBusinessWillRecieveDirectlyReq:
    "Business will Receive Directly Buyer Request",
  youCanManageAndSetBusinessPhone:
    "You can Manage and set Default Phone Number For Request",
  startUpFormSettings: "Business form settings",
  steps: "Steps",
  addNewStep: "Add New Step",
  editExistingStep: "Edit New Step",
  titleInEnglish: "Title In English",
  titleInArabic: "Title In Arabic",
  typeTitleInEnglish: "Type title in english",
  typeTitleInArabic: "اكتب العنوان باللغة العربية",
  typeTitleInArabic: "Type title in arabic",
  pleaseSelectImage: "Please Select Image",
  addExpertSuccessfully: "Added Expert Successfully!",
  changeStatusHireExpertSuccessfull: "Change Status Successfull!",
  hireExpertUpdatedSuccess: "Hire Expert Updated Successfully!",
  subAdminUpdatedSuccess: "Sub Admin Updated Successfully!",
  businessName: "Business Name",
  businessCategory: "Business Category",
  report: "Report",
  reportStartupReason: "Report Business Reason",
  reportReason: "Report Reason",
  businesses: "Businesses",
  notAvailable: "N/A",
  countries: "Countries",
  cities: "Cities",
  cityName: "City Name",
  cityInEnglish: "City Name (English)",
  cityInArabic: "City Name (Arabic)",
  addNewCity: "Add New City",
  nameInEnglish: "Name in English",
  nameInArabic: "Name in Arabic",
  latitude: "Latitude",
  longitude: "Longitude",
  editCity: "Edit City",
  citySettingText: "You can Manage and add Cities",
  businessNameIsHidden: "Business name is hidden",
  documentAreConfidentailsAndWontBeSharedWithBuyers:
    "Documents are confidentials and won’t be shared with buyers",
  chooseFile: "Choose File",
  fileUploadedSuccess: "File Uploaded Successfully!",
  wrongFileType: "File type not supported!",
  done: "Done",
  skip: "Skip",
  submit: "Submit",
  addMarketingCodeIfAvailable: "Add Marketing Code If Available",
  verifyAndListingisReady: "Verified & Listing is ready to submit",
  pleaseFillAllRequiredFields: "Please fill all required fields!",
  buyerCannotSeeLocation: "Buyer cannot see exact location of the business",
  noDraftBusinesses: "None drafted business!",
  noBusinessAddedForThisUser: "No business available!",
};

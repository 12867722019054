import IstehwathLogo from "../assets/Images/istewath-logo.svg";
import VerifyOtpLogo from "../assets/Images/verify-otp.svg";
import ForgotKey from "../assets/Images/forgot-key.svg";
import SuccessTick from "../assets/Images/success-tick.svg";
import CalanderIcon from "../assets/Images/calanderIcon.svg";
import CrossIcon from "../assets/Images/cross.svg";
import checkIcon from "../assets/Images/checkIcon.svg";
import BuyerIcon from "../assets/Images/buyer.svg";
import SellerIcon from "../assets/Images/seller.svg";
import CameraIcon from "../assets/Images/camera.svg";
import SearchIcon from "../assets/Images/search.svg";
import DownloadIcon from "../assets/Images/download-icon.svg";
import FilterIcon from "../assets/Images/filter-icon.svg";
import PlusIcon from "../assets/Images/plus.icon.svg";
import UserImage from "../assets/Images/userImage.png";
import LocationIcon from "../assets/Images/locationIcon.svg";
import DotedMenuIcon from "../assets/Images/dottedMenuIcon.svg";
import ForwardIcon from "../assets/Images/forwardIcon.svg";
import CertifiedBatch from "../assets/Images/CertifiedBatch.svg";
import GoldenBatch from "../assets/Images/goldenBatchIcon.svg";
import DefaultAvatar from "../assets/Images/profileAvatar.png";
import ForwardArrow from "../assets/Images/forword-arrow.svg";
import AddImageIcon from "../assets/Images/addImageIcon.svg";
import InfoIcon from "../assets/Images/infoIcon.svg";
import BackArrow from "../assets/Images/backArrow.svg";
import ApproveIcon from "../assets/Images/approveIcon.svg";
import DenyIcon from "../assets/Images/denyIcon.svg";
import SaudiFlag from "../assets/Images/saudiFlag.png";
import SuccessIcon from "../assets/Images/successIcon.svg";
import GrossRevenue from "../assets/Images/gross-revenue.svg";
import NetRevenue from "../assets/Images/net-revenue.svg";
import ArrowDown from "../assets/Images/arrow-down.svg";
import TickIcon from "../assets/Images/tick.svg";
import ForwordArrowBlue from "../assets/Images/forword-arrow-blue.svg";
import PercentageSymbol from "../assets/Images/percentageSymbol.svg";
import EditIcon from "../assets/Images/editIcon.svg";
import AddFormIcon from "../assets/Images/add-form-icon.svg";
import InputIcon from "../assets/Images/input-icon.svg";
import DropdownIcon from "../assets/Images/dropdown-icon.svg";
import MonthYearIcon from "../assets/Images/month-year-icon.svg";
import FileIcon from "../assets/Images/file-icon.svg";
import TextareaIcon from "../assets/Images/textarea-icon.svg";
import RadioIcon from "../assets/Images/radio-icon.svg";
import TagsIcon from "../assets/Images/tags-icon.svg";
import RedLocationIcon from "../assets/Images/red-location-icon.svg";
import DropdownArrowIcon from "../assets/Images/dropdown-arrow-icon.svg";
import LocationInputIcon from "../assets/Images/location-input-icon.svg";
import CrossFormIcon from "../assets/Images/cross-form-icon.svg";
import GoldenBadgeIcon from "../assets/Images/golden-badge-icon.svg";
import DeleteIcon from "../assets/Images/delete-icon.svg";
import DeleteIconRed from "../assets/Images/red-delete-icon.svg";
import Delete from "../assets/Images/delete.svg";
import NoSearchFound from "../assets/Images/nosearchfound.svg";
import uploadIcon from "../assets/Images/uploadIcon.svg";
import info from "../assets/Images/info.svg";
import addIcon from "../assets/Images/addIcon.svg";
import mp4Icon from "../assets/Images/mp4Icon.png";
import docIcon from "../assets/Images/docIcon.png";
import PdfFileIcon from "../assets/Images/pdfIcon.png";
import attachmentImg from "../assets/Images/attachment-icon.svg";

export const Images = {
  IstehwathLogo,
  VerifyOtpLogo,
  attachmentImg,
  PdfFileIcon,
  mp4Icon,
  docIcon,
  DeleteIcon,
  Delete,
  info,
  addIcon,
  MonthYearIcon,
  RedLocationIcon,
  TagsIcon,
  LocationInputIcon,
  CrossFormIcon,
  TextareaIcon,
  GoldenBadgeIcon,
  uploadIcon,
  RadioIcon,
  ForgotKey,
  FileIcon,
  InputIcon,
  SuccessTick,
  DropdownIcon,
  CalanderIcon,
  CrossIcon,
  checkIcon,
  BuyerIcon,
  SellerIcon,
  CameraIcon,
  SearchIcon,
  DownloadIcon,
  FilterIcon,
  DropdownArrowIcon,
  PlusIcon,
  UserImage,
  LocationIcon,
  DotedMenuIcon,
  ForwardIcon,
  CertifiedBatch,
  GoldenBatch,
  DefaultAvatar,
  ForwardArrow,
  AddImageIcon,
  InfoIcon,
  BackArrow,
  DenyIcon,
  ApproveIcon,
  SaudiFlag,
  SuccessIcon,
  GrossRevenue,
  NetRevenue,
  ArrowDown,
  TickIcon,
  ForwordArrowBlue,
  PercentageSymbol,
  EditIcon,
  AddFormIcon,
  DeleteIconRed,
  NoSearchFound,
};

import React, { Fragment, useCallback, useState } from "react";
import { labels } from "../../assets/labels";
import Button from "../../components/Button";
import { checkInternetConnection, validateText } from "../../utils/validate";
import { useNavigate,useLocation  } from "react-router";
import PasswordInput from "../../components/PasswordInput";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { resetPasswordAction } from "../../store/actions";
import Spinner from "../../components/spinner/authLoader";
import { Images } from "../../utils/images";

const {ForgotKey,IstehwathLogo} = Images;


const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);


  const [values, setValues] = useState({
    password: "",
    newPassword: "",
  });
  const [errors, setErrors] = useState({
    password: null,
    newPassword: null,
  });

  const handleForgotPassword = useCallback((e) => {
    e.preventDefault();
    if (!values.password) {
      const textError = validateText(values.password);
      setErrors((prevState) => ({
        ...prevState,
        password: textError,
      }));
    }
    if (!values.newPassword) {
      const textError = validateText(values.newPassword);
      setErrors((prevState) => ({
        ...prevState,
        newPassword: textError,
      }));
    }
    if( values.password !== values.newPassword) {
      return toast.error("Password doesn't match!");
    }
    if (values.password && values.newPassword) {
      if (Boolean(checkInternetConnection())) {
        const payload = {userId: location?.state,password: values.newPassword};
       
        setIsLoading(true);
        dispatch(resetPasswordAction(payload, () => {
          setIsLoading(false);
          navigate("/change-password-success")
        }));
      }
    }
     
  
    
},[values, setErrors,dispatch]);
  return (
    <Fragment>
      <div className="w-full min-h-screen grid place-content-center">
        <div className="flex flex-col gap-y-16 items-center justify-between">
          <img src={IstehwathLogo} alt="Otp Logo" className="object-cover" />

          <div className="flex justify-center items-center flex-col">
            <div className="mb-8">
              <img
                src={ForgotKey}
                alt="Istehwath Logo"
                className="object-cover w-[141.99px] h-[117px]"
              />
            </div>
            <p className="text-c_000000 font-general_semiBold font-semibold text-[32px]">
              {labels.forgotPassword}
            </p>
            <p className="text-[13px] font-general_regular text-c_0E1014 font-normal">
              {labels.enterYourEmailWeWillSendOtp}
            </p>
          </div>
        </div>
        <form>
          <div className="flex flex-col gap-3 mt-8 mb-8">
          <PasswordInput
            id="password"
            name="password"
            value={values.password}
            onChange={(e) => {
              setErrors((prevState) => ({
                ...prevState,
                password: null,
              }));
              setValues((prevState) => ({
                ...prevState,
                password: e.target.value.replace(/\s/g, ""),
              }));
            }}
            error={Boolean(errors.password)}
            errorText={errors.password}
            className={`w-full border border-c_D9D9D9 rounded-[12px] py-2 px-4 text-[16px] font-general_regular text-c_0E1014 outline-none placeholder:!text-c_D9D9D9`}
            placeholder={labels.newPassword}
          />
            <PasswordInput
            id="newPassword"
            name="newPassword"
            value={values.newPassword}
            onChange={(e) => {
              setErrors((prevState) => ({
                ...prevState,
                newPassword: null,
              }));
              setValues((prevState) => ({
                ...prevState,
                newPassword: e.target.value.replace(/\s/g, ""),
              }));
            }}
            error={Boolean(errors.newPassword)}
            errorText={errors.newPassword}
            className={`w-full border border-c_D9D9D9 rounded-[12px] py-2 px-4 text-[16px] font-general_regular text-c_0E1014 outline-none placeholder:!text-c_D9D9D9`}
            placeholder={labels.ConfirmNewPassword}
          />
            <Button
              label={isLoading ? <Spinner /> : labels.save}
              disabled={isLoading && errors?.password || errors.newPassword}
              onClick={handleForgotPassword}
              className="bg-gradient-to-r from-[#1C2F40]  to-[#20415E] h-[49px] font-general_medium font-medium  text-[16px] text-c_FFFFFF rounded-[8px] mt-3"
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default ChangePassword;

import React, { Fragment } from "react";
import { labels } from "../../assets/labels";
import Button from "../../components/Button";
import { useNavigate } from "react-router";
import { Images } from "../../utils/images";

const ChangePasswordSuccess = () => {
  const {SuccessTick,IstehwathLogo} = Images;
  const navigate = useNavigate();
 

 
  return (
    <Fragment>
      <div className="w-full min-h-screen grid place-content-center">
        <div className="flex flex-col gap-y-16 items-center justify-between">
          <img src={IstehwathLogo} alt="Otp Logo" className="object-cover" />

          <div className="flex justify-center items-center flex-col">
            <div className="mb-8">
              <img
                src={SuccessTick}
                alt="Istehwath Logo"
                className="object-cover w-[87px] h-[87px]"
              />
            </div>
            <p className="text-c_000000 font-general_semiBold font-semibold text-[32px]">
              {labels.allDone}
            </p>
            <p className="text-[13px] font-general_regular text-c_0E1014 font-normal">
              {labels.yourPasswordReset}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-3 mt-8 mb-8">
        <Button
              label={labels.continue}
              // disabled={loading}
              // loading={loading}
              onClick={() => navigate("/login")}
              className="bg-gradient-to-r from-[#1C2F40]  to-[#20415E] h-[49px] font-general_medium font-medium  text-[16px] text-c_FFFFFF rounded-[8px] mt-3"
            />
        </div>
      
      </div>
    </Fragment>
  );
};

export default ChangePasswordSuccess;

/** @format */

import React, { Fragment } from "react";
import Spinner from "./spinner/authLoader";

const Button = ({
  label,
  disabled,
  loading,
  icon,
  onClick,
  className,
  type,
  spinnerColor,
  ref,
  ...props
}) => {
  return (
    <button
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      className={`${className}`}
      ref={ref}
      {...props}
    >
      {loading ? (
        <div role={"status"}>
          <Spinner color={spinnerColor} />
        </div>
      ) : (
        <Fragment>
          {icon}
          {label}
        </Fragment>
      )}
    </button>
  );
};

export default Button;

import Logo from "../../assets/Images/istewath-logo.svg";
import { Circles } from "react-loader-spinner";

const SpinnerComponent = ({ block = true, children }) => {
  if (block) {
    document.body.style.overflow = "auto";
  } else {
    document.body.style.overflow = "auto";
  }
  return (
    <div
      role="status"
      className="bg-offWhite min-h-screen flex items-center justify-center"
    >
      <div className="flex items-center flex-col gap-y-1  min-h-screen inset-0  justify-center  cursor-loading w-full  ">
        <img className="w-40 h-40" src={Logo} alt="logo" draggable={false} />
        {children}
        {block ? (
          <Circles
            height="60"
            width="60"
            color="#1F3C55"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : null}

        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default SpinnerComponent;

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../src/firebase_setup/firebase.js";


ReactDOM.createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    <Provider store={store}>
    <Routes>
      <Route path="/*" element={<App />} />
    </Routes>
    </Provider>
  </BrowserRouter>
);

/** @format */

import { Fragment, useState, memo, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../utils/classNames";
import Logo from "../assets/Images/logo.svg";
import NavbarMenu from "./NavbarMenu";
import { useNavigate } from "react-router";
import { getAccessControlAction } from "../store/actions/SubAdminActions";
import { useDispatch } from "react-redux";
import { getBusinessSettingAction } from "../store/actions/StartupFormActions";
import { Link } from "react-router-dom";

const Sidebar = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [accessController, setAccessController] = useState([]);
  const subAdmin = JSON.parse(localStorage.getItem("adminInfo"))
    ?.accountTypes?.[0]?.type;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (subAdmin === "subadmin") {
      dispatch(
        getAccessControlAction((res) => {
          setAccessController(res);
        }),
      );
    }
  }, [subAdmin]);

  useEffect(() => {
    getBusinessSettingAction((res) => {
      localStorage.setItem(
        "businessPhoneNumber",
        res?.find((item) => item.key === "businessPhoneNumber")?.value,
      );
    });
  }, []);
  // console.log(accessController,"accessController")

  const handleCheckNavigationCondition = (href) => {
    if (window.location.pathname === href) {
      return true;
    } else if (
      href.includes("user") &&
      window.location.pathname.includes("user")
    ) {
      return true;
    } else if (
      href.includes("business-requests") &&
      window.location.pathname === "/business-requests" &&
      window.location.pathname.includes("startup-details") &&
      window.location.pathname !== "/reported-startups" &&
      window.location.pathname !== "/business-form-setting"
    ) {
      return true;
    } else if (
      href.includes("contact-request") &&
      window.location.pathname.includes("contact-request")
    ) {
      return true;
    } else if (
      href.includes("sub-admin") &&
      href.includes("sub-admin-details/123") &&
      window.location.pathname.includes("subadmin")
    ) {
      return true;
    } else if (
      href === "/sub-admin" &&
      window.location.pathname.includes("sub-admin-details/")
    ) {
      return true;
    } else if (
      href.includes("promo-code") &&
      window.location.pathname.includes("promo-code")
    ) {
      return true;
    } else if (
      href.includes("reported-business") &&
      window.location.pathname.includes("reported-business") &&
      window.location.pathname === "/business-requests"
    ) {
      return true;
    } else if (
      href.includes("contact-request") &&
      window.location.pathname.includes("contact-request") &&
      window.location.pathname === "/contact-request"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/subscription"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/add-phoneno"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/business-setting"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/startup-form-setting"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/add-form-settings"
    ) {
      return true;
    } else if (
      href.includes("users") &&
      window.location.pathname === "/add-startup"
    ) {
      return true;
    } else if (
      href === "/business-requests" &&
      window.location.pathname === "/startup-details"
    ) {
      return true;
    } else if (
      href === "/marketing-code" &&
      window.location.pathname === "/marketing-code-details"
    ) {
      return true;
    } else if (
      href === "/settings" &&
      window.location.pathname === "/add-phone-number"
    ) {
      return true;
    } else if (
      href === "/settings" &&
      window.location.pathname === "/business-setting-detail"
    ) {
      return true;
    } else if (
      href === "/settings" &&
      window.location.pathname === "/subscription-detail"
    ) {
      return true;
    } else if (
      href === "/settings" &&
      window.location.pathname === "/add-form-settings"
    ) {
      return true;
    } else if (
      href.includes("setting") &&
      window.location.pathname === "/cities"
    ) {
      return true;
    }
  };
  const navigationForSubAdmin = [
    ...(accessController?.some(
      (item) => item.features === "dashboard" && item.read,
    )
      ? [{ name: "Dashboard", href: "/dashboard", current: true }]
      : []),
    ...(accessController?.some((item) => item.features === "users" && item.read)
      ? [{ name: "Users", href: "/users", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "startup" && item.read,
    )
      ? [
          {
            name: "Business Requests",
            href: "/business-requests",
            current: false,
          },
        ]
      : []),
    ...(accessController?.some(
      (item) => item.features === "request" && item.read,
    )
      ? [{ name: "Contact Request", href: "/contact-request", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "marketingCode" && item.read,
    )
      ? [{ name: "Marketing Code", href: "/marketing-code", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "promoCode" && item.read,
    )
      ? [{ name: "Discount Code", href: "/discount-code", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "hireExpert" && item.read,
    )
      ? [{ name: "Hire Expert", href: "/hire-expert", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "promote" && item.read,
    )
      ? [{ name: "Promote", href: "/promote", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "payment" && item.read,
    )
      ? [{ name: "Payments", href: "/payments", current: false }]
      : []),
    ...(accessController?.some(
      (item) => item.features === "reportedStartups" && item.read,
    )
      ? [
          {
            name: "Reported Business",
            href: "/reported-business",
            current: false,
          },
        ]
      : []),
    { name: "Settings", href: "/settings", current: false },
  ];
  const navigationForNonSubAdmin = [
    { name: "Dashboard", href: "/dashboard", current: true },
    { name: "Users", href: "/users", current: false },
    { name: "Business Requests", href: "/business-requests", current: false },
    { name: "Contact Request", href: "/contact-request", current: false },
    { name: "Sub Admin", href: "/sub-admin", current: false },
    { name: "Marketing Code", href: "/marketing-code", current: false },
    { name: "Discount Code", href: "/discount-code", current: false },
    { name: "Hire Expert", href: "/hire-expert", current: false },
    { name: "Promote", href: "/promote", current: false },
    { name: "Reported Business", href: "/reported-business", current: false },
    { name: "Payments", href: "/payments", current: false },
    { name: "Settings", href: "/settings", current: false },
  ];
  const navigation =
    subAdmin === "subadmin" ? navigationForSubAdmin : navigationForNonSubAdmin;

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as='div'
            className='relative z-50 lg:hidden'
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter='transition-opacity ease-linear duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='transition-opacity ease-linear duration-300'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-c_121516/80' />
            </Transition.Child>

            <div className='fixed inset-0 flex'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
              >
                <Dialog.Panel className='relative mr-16 flex w-full max-w-xs flex-1'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-in-out duration-300'
                    enterFrom='opacity-0'
                    enterTo='opacity-100'
                    leave='ease-in-out duration-300'
                    leaveFrom='opacity-100'
                    leaveTo='opacity-0'
                  >
                    <div className='absolute left-full top-0 flex w-16 justify-center pt-5'>
                      <button
                        type='button'
                        className='-m-2.5 p-2.5'
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className='sr-only'>Close sidebar</span>
                        <XMarkIcon
                          className='h-6 w-6 text-c_000'
                          aria-hidden='true'
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-c_F9F9F9 px-6 pb-4'>
                    <div className='flex h-16 shrink-0 items-center my-4'>
                      <img
                        className='h-16 w-16'
                        src={Logo}
                        alt='Istehwath Logo'
                      />
                    </div>
                    <nav className='flex flex-1 flex-col'>
                      <ul className='flex flex-1 flex-col gap-y-7'>
                        <li>
                          <ul className='-mx-2 space-y-1'>
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    handleCheckNavigationCondition(item.href)
                                      ? "flex items-center bg-gradient-to-r from-g_1C2F40 to-g_20415E text-f_16 font-general_light font-normal text-c_FFFFFF bg-c_ED3237"
                                      : "flex items-center text-c_454545",
                                    "group flex gap-x-3 mb-2 py-3 px-4 text-base leading-6 font-general_light text-f_16  font-normal rounded-[12px]",
                                  )}
                                >
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className='hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-80 lg:flex-col'>
          <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-c_F9F9F9 px-8 pb-4'>
            <div className='flex h-auto my-4 shrink-0 items-center justify-center'>
              <img
                className='w-48 h-24 mix-blend-multiply cursor-pointer'
                src={Logo}
                alt='Istehwath Logo'
                onClick={() => navigate("/dashboard")}
              />
            </div>
            <nav className='flex flex-1 flex-col'>
              <ul className='flex flex-1 flex-col gap-y-7'>
                <li>
                  <ul className='-mx-2 space-y-1'>
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            handleCheckNavigationCondition(item.href)
                              ? "flex items-center bg-gradient-to-r from-g_1C2F40 to-g_20415E text-f_16 font-general_light font-normal text-c_FFFFFF bg-c_ED3237"
                              : "flex items-center text-c_454545",
                            "group flex gap-x-3 mb-2 py-3 px-4 text-base leading-6 text-f_16  font-general_light font-normal rounded-[12px]",
                          )}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='lg:pl-80'>
          <div className='relative bg-c_F9F9F9 flex h-24 shrink-0 items-center gap-x-4 border-b-[2px] border-c_F3F3F3 px-4 sm:gap-x-6 sm:px-6 lg:px-8'>
            <div className='w-full flex items-center flex-row-reverse justify-between md:justify-start'>
              <div className='flex items-center md:ml-auto'>
                <NavbarMenu />
              </div>
              <button
                type='button'
                className='-m-2.5 p-2.5 text-c_0E1014 lg:hidden'
                onClick={() => setSidebarOpen(true)}
              >
                <span className='sr-only'>Open sidebar</span>
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
          </div>
          <div className='py-6 md:min-h-screen overflow-x-hidden'>
            <div className='px-4 sm:px-6 lg:px-8 pb-6'>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Sidebar);

/** @format */

export const ADMIN_DETAIL = "ADMIN_DETAIL";
export const SUB_ADMIN_DETAIL = "SUB_ADMIN_DETAIL";
export const HIRE_EXPERT_SUCCESSFULL = "HIRE_EXPERT_SUCCESSFULL";
export const HIRE_EXPERT_LOADING = "HIRE_EXPERT_LOADING";
export const HIRE_EXPERT_FAILED = "HIRE_EXPERT_FAILED";
export const UPDATE_HIRE_EXPERT_LOADING = "UPDATE_HIRE_EXPERT_LOADING";
export const UPDATE_HIRE_EXPERT_FAILED = "UPDATE_HIRE_EXPERT_FAILED";
export const UPDATE_HIRE_EXPERT_SUCCESSFULL = "UPDATE_HIRE_EXPERT_SUCCESSFULL";
export const REPORT_STARTUP_LOADING = "REPORT_STARTUP_LOADING";
export const REPORT_STARTUP_FAILED = "REPORT_STARTUP_FAILED";
export const REPORT_STARTUP_SUCCESSFULL = "REPORT_STARTUP_SUCCESSFULL";

import { useState } from "react";

const PasswordInput = ({
  className,
  error,
  onChange,
  errorText,
  value,
  disabled,
  placeholder,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className="relative container mx-auto">
      <input
        type={isPasswordVisible ? "text" : "password"}
        className={`w-full text-base outline-none py-1 transition-colors duration-200 ease-in-out ${className} ${
          error && "border border-c_FF0000"
        }`}
        {...props}
        onChange={onChange}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
      />
      {error && (
        <span className="text-c_FF0000 text-sm block pt-2 font-general_regular font-normal">
          {errorText}
        </span>
      )}
      <button
        type="button"
        className={
          error
            ? "absolute inset-y-0 right-0 mb-4 pb-2 flex items-center px-4"
            : "absolute inset-y-0 right-0 flex items-center px-4"
        }
        onClick={togglePasswordVisibility}
      >
        {!isPasswordVisible ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-5 h-5 text-c_D9D9D9"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="13"
            viewBox="0 0 20 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6.26C0.0266667 6.20667 0.06 6.13333 0.1 6.04C0.14 5.94667 0.24 5.75333 0.4 5.46C0.56 5.16667 0.733333 4.88667 0.92 4.62C1.10667 4.35333 1.36667 4.03333 1.7 3.66C2.03333 3.28667 2.38 2.93333 2.74 2.6C3.1 2.26667 3.54667 1.94 4.08 1.62C4.61333 1.3 5.16 1.02 5.72 0.780001C6.28 0.540001 6.93333 0.353334 7.68 0.220001C8.42667 0.0866671 9.2 0.0133333 10 0C10.8 -0.0133333 11.5733 0.0600004 12.32 0.220001C13.0667 0.380001 13.7267 0.573333 14.3 0.799999C14.8733 1.02667 15.4133 1.3 15.92 1.62C16.4267 1.94 16.8733 2.27333 17.26 2.62C17.6467 2.96667 17.9933 3.30667 18.3 3.64C18.6067 3.97333 18.8667 4.30667 19.08 4.64C19.2933 4.97333 19.4667 5.24667 19.6 5.46C19.7333 5.67333 19.8333 5.86667 19.9 6.04L20 6.26C19.9867 6.31333 19.9533 6.38667 19.9 6.48C19.8467 6.57333 19.7467 6.76 19.6 7.04C19.4533 7.32 19.28 7.6 19.08 7.88C18.88 8.16 18.62 8.48667 18.3 8.86C17.98 9.23333 17.6333 9.58 17.26 9.9C16.8867 10.22 16.44 10.5467 15.92 10.88C15.4 11.2133 14.8533 11.4933 14.28 11.72C13.7067 11.9467 13.0533 12.1333 12.32 12.28C11.5867 12.4267 10.8133 12.5 10 12.5C9.18667 12.5 8.41333 12.4267 7.68 12.28C6.94667 12.1333 6.28667 11.9467 5.7 11.72C5.11333 11.4933 4.57333 11.2133 4.08 10.88C3.58667 10.5467 3.14 10.22 2.74 9.9C2.34 9.58 1.99333 9.23333 1.7 8.86C1.40667 8.48667 1.14667 8.16 0.92 7.88C0.693333 7.6 0.52 7.32 0.4 7.04C0.28 6.76 0.18 6.56667 0.1 6.46L0 6.26ZM6.26 6.26C6.26 7.28667 6.62 8.16667 7.34 8.9C8.06 9.63333 8.94667 10 10 10C11.0533 10 11.94 9.63333 12.66 8.9C13.38 8.16667 13.7467 7.28667 13.76 6.26C13.7733 5.23333 13.4067 4.34667 12.66 3.6C11.9133 2.85333 11.0267 2.48667 10 2.5C8.97333 2.51333 8.08667 2.88 7.34 3.6C6.59333 4.32 6.23333 5.20667 6.26 6.26ZM7.5 6.26C7.5 5.56667 7.74667 4.97333 8.24 4.48C8.73333 3.98667 9.32 3.74667 10 3.76C10.68 3.77333 11.2667 4.01333 11.76 4.48C12.2533 4.94667 12.5 5.54 12.5 6.26C12.5 6.98 12.2533 7.56667 11.76 8.02C11.2667 8.47333 10.68 8.72 10 8.76C9.32 8.8 8.73333 8.55333 8.24 8.02L10 6.26H7.5Z"
              fill="#D9D9D9"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default PasswordInput;

/** @format */

import React, { Fragment, forwardRef, memo } from "react";

const Input = forwardRef(
  (
    {
      className,
      error,
      onChange,
      type,
      placeholder,
      defaultChecked,
      value,
      checked,
      errorText,
      maxLength,
      min,
      max,
      id,
      disabled,
      dir,
      defaultValue,
      onKeyDown,
      ...props
    },
    ref,
  ) => {
    return (
      <Fragment>
        <input
          className={`border border-c_535353 px-2 ${className} ${
            !!error && "border-c_FF0000 border font-general_regular font-normal"
          }`}
          onChange={onChange}
          value={value}
          min={min}
          disabled={disabled}
          max={max}
          type={type}
          onKeyDown={onKeyDown}
          ref={ref}
          id={id}
          checked={checked}
          placeholder={placeholder}
          defaultChecked={defaultChecked}
          dir={dir}
          {...props}
          maxLength={maxLength}
          defaultValue={defaultValue}
        />
        {!!error ? (
          <span className='text-c_FF0000 text-sm block font-general_regular font-normal'>
            {errorText}
          </span>
        ) : null}
      </Fragment>
    );
  },
);
Input.displayName = "Input";
export default memo(Input);

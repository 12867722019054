/** @format */

import { Suspense, lazy } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import SpinnerComponent from "../components/spinner/spinnerComponent";
import Login from "../pages/Login";
import VerifyOtp from "../pages/VerifyOtp";
import ForgotPassword from "../pages/ForgotPassword";
import ChangePasswordSuccess from "../pages/ChangePasswordSuccess";
import ChangePassword from "../pages/ChangePassword";
import ForgotPasswordOtp from "../pages/ForgotPasswordOtp";
import Sidebar from "../layout/Sidebar";

const routes = [
  {
    id: "1",
    path: "/login",
    component: lazy(() => import("../pages/Login")),
  },
  {
    id: "2",
    path: "/verify-otp",
    component: lazy(() => import("../pages/VerifyOtp")),
  },
  {
    id: "3",
    path: "/forgot-password",
    component: lazy(() => import("../pages/ForgotPassword")),
  },
  {
    id: "4",
    path: "/dashboard",
    component: lazy(() => import("../pages/Dashboard")),
  },
  {
    id: "5",
    path: "/users",
    component: lazy(() => import("../pages/Users")),
  },
  {
    id: "6",
    path: "/business-requests",
    component: lazy(() => import("../pages/Startup")),
  },
  {
    id: "7",
    path: "/contact-request",
    component: lazy(() => import("../pages/Request")),
  },
  {
    id: "8",
    path: "/sub-admin",
    component: lazy(() => import("../pages/SubAdmin")),
  },
  {
    id: "9",
    path: "/marketing-code",
    component: lazy(() => import("../pages/MarketingCode")),
  },
  {
    id: "10",
    path: "/discount-code",
    component: lazy(() => import("../pages/PromoCode")),
  },
  {
    id: "11",
    path: "/hire-expert",
    component: lazy(() => import("../pages/HireExpert")),
  },
  {
    id: "12",
    path: "/promote",
    component: lazy(() => import("../pages/Promote")),
  },
  {
    id: "13",
    path: "/reported-business",
    component: lazy(() => import("../pages/ReportedStartups")),
  },
  {
    id: "14",
    path: "/settings",
    component: lazy(() => import("../pages/Setting")),
  },
  {
    id: "15",
    path: "/forgot-password-otp",
    component: lazy(() => import("../pages/ForgotPasswordOtp")),
  },
  {
    id: "16",
    path: "/change-password",
    component: lazy(() => import("../pages/ChangePassword")),
  },
  {
    id: "17",
    path: "/change-password-success",
    component: lazy(() => import("../pages/ChangePasswordSuccess")),
  },
  {
    id: "18",
    path: "/user-details/:Id",
    component: lazy(() => import("../pages/UserDetails")),
  },
  {
    id: "19",
    path: "/edit-user",
    component: lazy(() => import("../pages/EditUser")),
  },
  {
    id: "20",
    path: "/add-business",
    component: lazy(() => import("../pages/AddStartUp")),
  },
  {
    id: "21",
    path: "/sub-admin-details/:Id",
    component: lazy(() => import("../pages/SubAdminDetails")),
  },
  {
    id: "22",
    path: "/business-details",
    component: lazy(() => import("../pages/StartupDetails")),
  },
  {
    id: "23",
    path: "/marketing-code-details",
    component: lazy(() => import("../pages/ViewMarketingCode")),
  },
  {
    id: "24",
    path: "/discount-code-details",
    component: lazy(() => import("../pages/ViewPromoCode")),
  },
  {
    id: "25",
    path: "/add-form-settings",
    component: lazy(() => import("../pages/AddFormSetting")),
  },
  {
    id: "26",
    path: "/subscription",
    component: lazy(() => import("../pages/Subscription")),
  },
  {
    id: "27",
    path: "/subscription-detail",
    component: lazy(() => import("../pages/SubscriptionDetail")),
  },
  {
    id: "28",
    path: "/business-setting",
    component: lazy(() => import("../pages/BusinessSetting")),
  },
  {
    id: "29",
    path: "/business-setting-detail",
    component: lazy(() => import("../pages/BusinessSettingDetail")),
  },
  {
    id: "30",
    path: "/add-phone-number",
    component: lazy(() => import("../pages/AddPhoneNo")),
  },
  {
    id: "31",
    path: "/business-form-setting",
    component: lazy(() => import("../pages/StartUpFormSetting")),
  },
  {
    id: "32",
    path: "/edit-business",
    component: lazy(() => import("../pages/EditStartUp")),
  },
  {
    id: "33",
    path: "/reported-business-details",
    component: lazy(() => import("../pages/ReportedStartupDetails")),
  },
  {
    id: "34",
    path: "/user-business-details",
    component: lazy(() => import("../pages/UserStartupDetails")),
  },
  {
    id: "35",
    path: "/seller-business-details",
    component: lazy(() => import("../pages/SellerStartupDetails")),
  },
  {
    id: "36",
    path: "/payments",
    component: lazy(() => import("../pages/Payment")),
  },
  {
    id: "37",
    path: "/buyer-details",
    component: lazy(() => import("../pages/BuyerDetails")),
  },
  {
    id: "37",
    path: "/cities",
    component: lazy(() => import("../pages/Cities")),
  },
];

const SidebarWrapper = () => (
  <>
    <Sidebar>
      <Outlet />
    </Sidebar>
  </>
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path='/login'
        element={
          <Suspense fallback={<SpinnerComponent />}>{<Login />}</Suspense>
        }
      />
      <Route path='/' element={<Navigate to='/login' replace />} />
      <Route
        path='/verify-otp'
        element={
          <Suspense fallback={<SpinnerComponent />}>{<VerifyOtp />}</Suspense>
        }
      />
      <Route
        path='/forgot-password'
        element={
          <Suspense fallback={<SpinnerComponent />}>
            {<ForgotPassword />}
          </Suspense>
        }
      />
      <Route
        path='/forgot-password-otp'
        element={
          <Suspense fallback={<SpinnerComponent />}>
            {<ForgotPasswordOtp />}
          </Suspense>
        }
      />
      <Route
        path='/change-password-success'
        element={
          <Suspense fallback={<SpinnerComponent />}>
            {<ChangePasswordSuccess />}
          </Suspense>
        }
      />
      <Route
        path='/change-password'
        element={
          <Suspense fallback={<SpinnerComponent />}>
            {<ChangePassword />}
          </Suspense>
        }
      />
      <Route element={<SidebarWrapper />}>
        {routes?.map((item) => (
          <Route
            key={item?.id}
            path={item?.path}
            element={<Suspense fallback={null}>{<item.component />}</Suspense>}
          ></Route>
        ))}
      </Route>
    </Routes>
  );
};

export default AppRoutes;

import toast from "react-hot-toast";
import { endPoint } from "../../api/EndPoint";
import _Api from "../../api/apiConfig";
import { labels } from "../../assets/labels";


const addFormStepAction = (obj, callBack) => {
    return async (dispatch) => {
      await _Api._post(
        endPoint.addFormStep,
        obj,
        (success) => {
          callBack(success);
          toast.success(success?.data?.message);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };
  const editDynamicFormAction = (obj, callBack) => {
    return async (dispatch) => {
      await _Api._patch(
        `${endPoint.editDynamicForm}`,
        obj,
        (success) => {
          callBack(success);
          toast.success(success?.data?.message);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };

  const editFormStepAction = (id,obj, callBack) => {
    return async (dispatch) => {
      await _Api._patch(
        `${endPoint.editFormStep}/${id}`,
        obj,
        (success) => {
          callBack(success);
          toast.success(success?.data?.message);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };
  
  // Add Dynamic Form Api 
  const addDynamicFormAction = (obj, callBack) => {
    return async (dispatch) => {
      await _Api._post(
        endPoint.addDynamicForm,
        obj,
        (success) => {
          // console.log(success,"success")
          callBack(success);
          toast.success(success?.data?.message);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };
//   // Add Business Setting Api Method
  const addBusinessSettingAction = (obj, callBack) => {
    return async (dispatch) => {
      await _Api._post(
        `${endPoint.addSetting}`,
        obj,
        (success) => {
          callBack(success);
          toast.success(success?.data?.message);
        },
        (error) => {
          toast.error(error);
        }
      );
    };
  };

    // Get Form Step  Api Method
 const getFormStepAction = async (form,params) => {
      const query = new URLSearchParams(params).toString();
      await _Api._get(
        `${endPoint.getFormStep}?${query}`,
        (success) => {
          form(success?.data?.data);
        },
        (err) => {
          toast.error(err);
        }
      );
  };

  const getLocationNameAction = async (form,params) => {
    const query = new URLSearchParams(params).toString();
    await _Api._get(
      `${endPoint.locationName}?${query}`,
      (success) => {
        form(success?.data?.data);
      },
      (err) => {
        toast.error(err);
      }
    );
};

      // Get Form Fields  Api Method
 const getFormFieldsAction = async (fields) => {
  await _Api._get(
    `${endPoint.getFormFields}`,
    (success) => {
      fields(success?.data?.data);
    },
    (err) => {
      toast.error(err);
    }
  );
};

    // Get Form Details  Api Method
    const getFormDetailsAction = async (id,forms) => {
      await _Api._get(
        `${endPoint.getFormDetails}/${id}`,
        (success) => {
          forms(success?.data?.data);
        },
        (err) => {
          toast.error(err);
        }
      );
    };

    const getStartupFormAction = async (startupForm) => {
      // const query = new URLSearchParams(params).toString();
      await _Api._get(
        `${endPoint.getStartupSellerForm}`,
        (success) => {
          startupForm(success?.data?.data);
        },
        (error) => {
          toast.error(error);
        }
      );
    };

    const getSingleStartupDetails = async (id, callBack) => {
      await _Api._get(
        `${endPoint.getSingleStartup}/${id}`,
        (success) => {
          callBack(success);
        },
        (error) => {
          toast.error(error);
        },
      );
    };

    const getEditStartupForm = async (id, callBack) => {
      await _Api._get(
        `${endPoint.getEditStartupForm}?id=${id}`,
        (success) => {
          callBack(success?.data);
        },
        (error) => {
          toast.error(error);
        },
      );
    };
    
    const getBusinessSettingAction = async (startupForm) => {
      await _Api._get(
        `${endPoint.businessSetting}`,
        (success) => {
          startupForm(success?.data?.data);
        },
        (error) => {
          toast.error(error);
        }
      );
    };

// Delete Step Api Method
 const deleteFormStepAction = async (id) => {
  await _Api._delete(
    `${endPoint.deleteStepForm}/${id}`,{},
    (success) => {
      toast.success(success?.data?.message);
    },
    (err) => {
      toast.error(err);
    }
  );
};
//       // Get User By ID Api Method
//  const getUserDetailsAction = async (id,user) => {
//   await _Api._get(
//     `${endPoint.users}/${id}`,
//     (success) => {
//       user(success?.data);
//     },
//     (err) => {
//       // console.log(err,"err")
//       toast.error(err);
//     }
//   );
// };

//   // Toggle Status change   Api Method
 const toggleStatusApiMethod = async (id) => {
  await _Api._patch(
    `${endPoint.stepFormStatusChange}/${id}`,{},
    (success) => {
      toast.success(success?.data?.message);
    },
    (err) => {
      toast.error(err);
    }
  );
};
const toggleVisibilityStatusApiMethod = async (id) => {
  await _Api._patch(
    `${endPoint.stepVisibility}/${id}`,{},
    (success) => {
      toast.success(success?.data?.message);
    },
    (err) => {
      toast.error(err);
    }
  );
};
  
  export {getFormStepAction,getLocationNameAction,getEditStartupForm,toggleVisibilityStatusApiMethod,editDynamicFormAction,getSingleStartupDetails,addBusinessSettingAction,getBusinessSettingAction,getStartupFormAction,getFormDetailsAction,addFormStepAction,getFormFieldsAction,addDynamicFormAction,deleteFormStepAction,editFormStepAction,toggleStatusApiMethod};
import React, { Fragment, useCallback, useState } from "react";
import Input from "../../components/Input";
import PasswordInput from "../../components/PasswordInput";
import Button from "../../components/Button";
import { labels } from "../../assets/labels";
import {
  checkInternetConnection,
  validateEmailAddress,
  validateText,
} from "../../utils/validate";
import { useNavigate } from "react-router";
import { Images } from "../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { adminLoginAction } from "../../store/actions";
import Spinner from "../../components/spinner/authLoader";

const { IstehwathLogo } = Images;

const Login = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    email: "",
    password: "",
    rememberMe: "",
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null,
  });

  const handleLogin = useCallback((e) => {
      e?.preventDefault();
      if (!values?.email) {
        const textError = validateText(values?.email);
        setErrors((prevState) => ({
          ...prevState,
          email: textError,
        }));
      }
      if (!values.password) {
        const textError = validateText(values.password);
        setErrors((prevState) => ({
          ...prevState,
          password: textError,
        }));
      }
      if (values?.email) {
        const emailError = validateEmailAddress(values?.email);
        setErrors((prevState) => ({
          ...prevState,
          email: emailError,
        }));
      }
      if (
        values?.email &&
        validateEmailAddress(values?.email) === null &&
        values.password
      ) {
        if (Boolean(checkInternetConnection())) {
          setIsLoading(true);
          const payload = { email: values?.email, password: values?.password };
          dispatch(
            adminLoginAction(
              payload,
              () => {
                setIsLoading(false);
                navigate("/verify-otp", { state: values?.email });
              },
              setIsLoading
            )
          );
        }
      }
    },
    [values, setErrors, dispatch]
  );
  return (
    <Fragment>
      <div className="w-full min-h-screen grid place-content-center">
        <div className="flex flex-col gap-y-16 items-center justify-between md:px-0 px-4">
          <div>
            <img
              src={IstehwathLogo}
              alt="Istehwath Logo"
              className="object-cover"
            />
          </div>
          <div className="text_container text-center flex flex-col gap-y-1">
            <p className="text-black font-general_semiBold font-semibold text-f_32 font-600">
              {labels.welcomeBackAdmin}
            </p>
            <p className="text-f_13 font-general_regular text-c_0E1014/60 font-normal">
              {labels.enterTheEmail}
            </p>
          </div>
        </div>
        <form>
          <div className="flex flex-col gap-3 mt-8">
            <Input
              id="email"
              name="email"
              type="email"
              value={values?.email}
              onChange={(e) => {
                setErrors((prevState) => ({
                  ...prevState,
                  email: null,
                }));
                setValues((prevState) => ({
                  ...prevState,
                  email: e?.target?.value,
                }));
              }}
              error={Boolean(errors?.email)}
              errorText={errors?.email}
              className={`w-full border border-c_D9D9D9 bg-transparent rounded-[12px] py-2 px-4 text-[16px] font-general_regular text-c_0E1014 outline-none placeholder:!text-c_D9D9D9`}
              placeholder={labels.emailAddress}
            />
            <div className="flex flex-col w-full justify-center relative">
              <PasswordInput
                id="password"
                name="password"
                value={values?.password}
                onChange={(e) => {
                  setErrors((prevState) => ({
                    ...prevState,
                    password: null,
                  }));
                  setValues((prevState) => ({
                    ...prevState,
                    password: e?.target?.value?.replace(/\s/g, ""),
                  }));
                }}
                error={Boolean(errors.password)}
                errorText={errors.password}
                className={`w-full border border-c_D9D9D9 bg-transparent rounded-[12px] py-2 px-4 text-f_16 font-general_light font-light text-c_0E1014 outline-none placeholder:!text-c_D9D9D9`}
                placeholder={labels.password}
              />
            </div>
            <div className="flex justify-end items-center">
              {/* <label className="flex items-center">
                <input
                  // onChange={handleCheckboxChange}
                  // checked={values.rememberMe}
                  className="mr-2 border border-c_D9D9D9 w-[18px] bg-c_E2E2E2 h-[18px]"
                  type="checkbox"
                />
                <span className="text-[13px] text-c_0E1014 font-general_medium font-medium select-none">
                  {labels.rememberMe}
                </span>
              </label> */}
              <span
                onClick={() => navigate("/forgot-password")}
                className="text-[13px] cursor-pointer text-c_181818 font-general_medium font-medium"
              >
                {labels.forgotPassword}
              </span>
            </div>

            <Button
              label={isLoading ? <Spinner /> : labels.login}
              type="submit"
              disabled={(isLoading && errors?.email) || errors.password}
              onClick={handleLogin}
              className="bg-gradient-to-r from-[#1C2F40]  to-[#20415E] h-[49px] font-general_medium font-medium  text-[16px] text-c_FFFFFF rounded-[8px] mt-3"
            />
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Login;

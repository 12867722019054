import toast from "react-hot-toast";

const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,12}$/im;
  // const phoneRegex = /^\+\d{1,3}\s?\d{7,15}$/;
const validateEmailAddress = (val) => {
  if (val && !emailRegex.test(val)) {
    return "Invalid email address";
  }
  return null;
};

const validateText = (val) => {
  if (!val) {
    return "Please fill this field";
  }
  return null;
};

const validateImage = (val) => {
  if (!val) {
    return "Please select an image";
  }
  return null;
};

const validateOnlySpace = (val) => {
  if (val.trim() === "") {
    return "Can't be only spaces";
  }
  return null;
};

const validateLength = (val) => {
  const trimmedVal = val.trim();

  if (trimmedVal.length < 3) {
    return "Minimum 3 (three) characters required";
  }
  return null;
};

const checkInternetConnection = () => {
  if (!navigator.onLine) {
    toast?.error("No Internet Connection Found!");
  }
  return navigator.onLine;
};

 const validatePhone =  (phone) =>  {
  if (phone && !phoneRegex.test(phone)) {
    return "Invalid phone number";
  }
  return null;
};

export function getEnvVariable(key)
 {
  if (window?.__ENV__ && window?.__ENV__[key]) {
    return window.__ENV__[key];
  }

  // Fallback to build-time variables
  if (import.meta.env[key]) {
    return import.meta.env[key];
  }

  console.error(`Environment variable ${key} not found.`);
  return undefined;
}

export {
  validateEmailAddress,
  validateText,
  validateImage,
  validateLength,
  validateOnlySpace,
  validatePhone,
  checkInternetConnection,
};
